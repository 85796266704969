import * as React from "react";
import Section from "@/components/Section/Section";
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import { InlineWidget } from "react-calendly";
import "../../App.scss";
import CookieCompliance from "@/components/CookieCompliance/CookieCompliance";

const DiscoveryCall = () => (
  <main>
    <CookieCompliance />
    <Header alwaysFixed></Header>

    <Section className="Videohero">
      <div className="Videohero__headline">
        <h6>It's time to get accurate data!</h6>
        <h1>Book a Demo Call below.</h1>
      </div>

      <InlineWidget url="https://calendly.com/ecomtrack/demo?primary_color=132bb0" />
    </Section>

    <Section>
      <div className="spc280"></div>
    </Section>

    <Footer colFlex></Footer>
  </main>
);

export default DiscoveryCall;
